var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "app-header navbar"
  }, [_vm.toastWelcome ? _c('div', {
    staticClass: "toast"
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_vm._v(" Welcome " + _vm._s(this.name) + " ")]), _c('div', {
    staticClass: "ml-3"
  }, [_c('svg', {
    staticClass: "w-3 h-3",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 14 14"
    },
    on: {
      "click": _vm.closeToast
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
    }
  })])])])]) : _vm._e(), _c('button', {
    staticClass: "navbar-toggler mobile-sidebar-toggler d-lg-none",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.mobileSidebarToggle
    }
  }, [_c('i', {
    staticClass: "fa fa-bars text-gray-500",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('button', {
    staticClass: "ml-3 navbar-toggler border-0 focus:outline-none sidebar-toggler d-md-down-none",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.sidebarToggle
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-bars text-2xl text-gray-500"
  })]), _c('b-link', {
    staticClass: "navbar-brand",
    attrs: {
      "to": "/"
    }
  }), _c('b-navbar-nav', {
    staticClass: "ml-auto"
  }, [_c('b-dropdown', {
    attrs: {
      "text": _vm.name,
      "variant": "link",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    }
  }, [_c('template', {
    slot: "button-content"
  }, [_vm._v(" " + _vm._s(_vm.auth ? _vm.auth.username : '-') + " "), _c('span', {
    staticClass: "fa fa-user-circle"
  })]), _c('b-dropdown-item'), _c('b-dropdown-header', {
    staticClass: "text-center",
    attrs: {
      "tag": "div"
    }
  }, [_c('strong', [_vm._v("Settings")])]), _c('b-dropdown-item', {
    on: {
      "click": _vm.showDetail
    }
  }, [_c('i', {
    staticClass: "fa fa-user"
  }), _vm._v(" Profile ")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.actionLogout();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-lock"
  }), _vm._v(" Logout")])], 2)], 1), _c('Toast')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }