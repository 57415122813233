<template>
  <header class="app-header navbar">
    <div v-if="toastWelcome" class="toast">
      <div class="flex justify-between items-center">
        <div> Welcome {{ this.name }} </div>
        <div class="ml-3">
          <svg @click="closeToast" class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </div>
      </div>
    </div>
    <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none"
      type="button"
      @click="mobileSidebarToggle"
    >
      <i class="fa fa-bars text-gray-500" aria-hidden="true"></i>
    </button>
    <button
      class=" ml-3 navbar-toggler border-0 focus:outline-none sidebar-toggler d-md-down-none"
      type="button"
      @click="sidebarToggle"
    >
    <i class="fa-solid fa-bars text-2xl text-gray-500"></i>
    </button>
    <b-link class="navbar-brand" to="/"> </b-link>
    <b-navbar-nav class="ml-auto">
      <!-- <label for="label-name" class="label mr-1">
        {{ auth ? auth.username : '-'}}
      </label> -->
      <b-dropdown
        :text="name"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      > 
        <template slot="button-content"
          >
        {{ auth ? auth.username : '-'}}
          <span class="fa fa-user-circle"></span
        ></template>
        <b-dropdown-item> </b-dropdown-item>
        <b-dropdown-header tag="div" class="text-center"
          ><strong>Settings</strong></b-dropdown-header
        >
        <b-dropdown-item @click="showDetail">
          <i class="fa fa-user"></i> Profile
        </b-dropdown-item>
        <b-dropdown-item @click="actionLogout()"
          ><i class="fa fa-lock"></i> Logout</b-dropdown-item
        >
      </b-dropdown>
    </b-navbar-nav>
    <Toast  />
  </header>
</template>
<script>
import{ mapState,mapMutations} from "vuex"
import Toast from '../../components/form/Toast.vue';

export default {
  name: "headers",
  components: {
    Toast
  },
  data() {
    return {
      name: "", 
      toastWelcome:false
    };
  },
  computed: {
    ...mapState({
      auth : (state) => state.auth.auth,
      isLogin:(state)=>state.auth.isAuthenticated,
    }),
  },
  mounted() {
    if (localStorage.getItem('showWelcomeToast')) {
      this.toastWelcome = true
      setTimeout(()=> {
            this.toastWelcome=false;
          },3500)
    }
      
  },
  methods: {
    ...mapMutations('auth',['setAuth',"setLogin"]),
    sidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-hidden");
    },
    sidebarMinimize(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-minimized");
    },
    mobileSidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-mobile-show");
    },
    asideToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("aside-menu-hidden");
    },
    closeToast(){
      this.toastWelcome=false;
    },
    actionLogout() {
       this.$swal
        .fire({
          title: "Are you sure?",
          text: "Logout Now",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Logout!",
        })
        .then((result) => {
          if (result.isConfirmed) {
             localStorage.clear();
            this.setLogin(false);
            this.setAuth({});
            window.location.reload();
          }
        });
    },
    showDetail() {
      this.$router.push({
        path: "/settings/profile"
      });
    }
  },
  created: function() {
    this.name = localStorage.getItem("session_name");
    let auth = localStorage.getItem("session_auth");
    if (this.name && auth) {
      this.setAuth(JSON.parse(auth));
    }
  }
};
</script>
<style>
.toast {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 50%);
    padding: 13px 20px;
    border-radius: 5px;
    color: white;
    background-color: #333;
    opacity: 1;
    z-index: 1000;
  }
  
.navbar-nav .nav-link,
.navbar-nav .navbar .dropdown-toggle,
.navbar .navbar-nav .dropdown-toggle {
  color: black;
}
</style>
